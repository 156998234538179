var axios = require('axios');

var cache_util = require('./CacheUtil');

// var IP_PATH = 'http://localhost:1991';
// var IP_PATH = 'http://10.2.89.43:1991';
// var IP_PATH = 'http://13.250.35.252:1991';
var IP_PATH = 'https://grosirpintar.tokopintar.co.id';
// var IP_PATH = 'https://grosirpintar.bersama.co.id';

const OPTION_API_MAIN = 6;
// const OPTION_API_MAIN = 2;

const OPTION_API_DEFAULT = 4;
// const OPTION_API_DEFAULT = 2;

function onRequestForm(option_type, route, body_form, listener) {
  axios.post(getURL(option_type, route), body_form, {
    headers : getHeaders()
  }).then((res_body) => {
    listener(res_body.data);
  }).catch(function(req_error) {
    let res_error = { code : 0, message : req_error.message };
    if (req_error.response !== undefined) {
      res_error = req_error.response.data;
    }
    listener(res_error);
  });
}

function onRequestData(option_type, route, body, listener) {
  body.view_id = route;
  axios(getOptions(option_type, route, body)).then(function(res_body) {
    listener(res_body.data);
  }).catch(function(req_error) {
    let res_error = { code : 0, message : req_error.message };
    if (req_error.response !== undefined) {
      res_error = req_error.response.data;
    }
    listener(res_error);
  });
}

function getOptions(option_type, route, body) {
  return {
    method : 'post',
    url : getURL(option_type, route),
    data : body,
    headers : getHeaders()
  }
}

function getHeaders() {
  let headers = { session : cache_util.onGetCacheString(cache_util.CACHE_SESSION) }
  let user_information = cache_util.onGetCacheData(cache_util.CACHE_INFO);
  if (user_information !== null) { headers.wholesaler = user_information.result.user_information.wholesaler_id; }
  return headers;
}

function getURL(option_type, route) {
  switch (option_type) {
    case 2 :
      return 'http://localhost:1995/monitor' + route;
    case 3 :
      return 'http://13.250.35.252:1995/monitor' + route;
    case 4 :
      return 'https://gpapi.tokopintarmayora.com/monitor' + route;
      // return 'https://gpapi.bersama.co.id/monitor' + route;
    case 5 :
      return 'http://192.168.43.176:1995/monitor' + route;
    case 6 :
      return 'https://gpweb.tokopintarmayora.com/monitor' + route;
      // return 'https://gpapi.bersama.co.id/monitor' + route;
    default:
      return getURL(OPTION_API_DEFAULT, route); // SEND DEFAULT
  }
}

module.exports = {
  onRequestData : onRequestData,
  onRequestForm : onRequestForm,
  IP_PATH : IP_PATH,
  OPTION_API_MAIN : OPTION_API_MAIN
}
