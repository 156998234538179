import React, { Component } from "react";

import gif_img from "./resources/loading.gif";
import img_error from "./resources/error.png";

var base64 = require("base-64");
var request = require("../utilities/NetworkRequest");
var str_util = require("../utilities/StringUtil");

export default class PrintOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      is_main_loading: true,
      is_error: false,
      error_message: "",
    };
  }

  componentDidMount() {
    try {
      let decoded_input = JSON.parse(
        base64.decode(this.props.match.params.data)
      );
      let body = {
        wholesaler_id:
          decoded_input.user_information !== undefined
            ? decoded_input.user_information.wholesaler_id
            : undefined,
        order_number: JSON.stringify(decoded_input.set_id.split("#")),
        page_id: decoded_input.view_id,
      };
      request.onRequestData(
        request.OPTION_API_MAIN,
        "/print/order",
        body,
        (res_data) => {
          if (decoded_input.label_sort === "nama-produk") {
            res_data.result.forEach((order) => {
              order.items.sort((a, b) => {
                if (a.pcode_name < b.pcode_name) return -1;
                if (a.pcode_name > b.pcode_name) return 1;
                return 0;
              });
            });
          }

          if (res_data.code === 1) {
            this.setState({
              is_main_loading: false,
              is_error: false,
              data: res_data.result,
            });
          } else {
            this.setState({
              is_main_loading: false,
              is_error: true,
              error_message: res_data.message,
            });
          }
        }
      );
    } catch (e) {
      this.setState({
        is_main_loading: false,
        is_error: true,
        error_message: e.message,
      });
    }
  }

  getUnitValue(data) {
    if (Number(data.qty_real_order) > 0) {
      return Math.trunc(
        Number(data.amount_sales_order) / Number(data.qty_real_order)
      );
    } else {
      return 0;
    }
  }

  initProductView(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <tr key={i} className="special_tr_product">
          <td className="whole_padding default_text_normal_gray">
            {data[i].pcode_name}
          </td>
          <td className="whole_padding special_th_data">
            <span className="default_text_normal_gray">
              {data[i].qty_real_a_processed}
            </span>{" "}
            <i className="default_text_normal_gray_c">{data[i].uom_a}</i>
          </td>
          <td className="whole_padding special_th_data">
            <span className="default_text_normal_gray">
              {data[i].qty_real_b_processed}
            </span>{" "}
            <i className="default_text_normal_gray_c">{data[i].uom_b}</i>
          </td>
          <td className="whole_padding default_text_normal_gray">
            {str_util.formatSeparator(this.getUnitValue(data[i]).toString())}
          </td>
          <td className="whole_padding default_text_normal_gray">
            {str_util.formatSeparator(data[i].amount_sales_order)}
          </td>
        </tr>
      );
    }
    return view;
  }

  initMessageView(data) {
    switch (data) {
      case 0:
        return (
          <div className="normal_padding_bottom_b side_padding_c default_text_normal_gray_b">
            Ritel yang memesan, mengizinkan pihak grosir untuk mengirim
            barangnya <b>tanpa harus sesuai</b> dengan jumlah yang dipesan.
          </div>
        );
      case 1:
        return (
          <div className="normal_padding_bottom_b side_padding_c default_text_normal_gray_b">
            Ritel yang memesan, meng-inginkan pihak grosir untuk mengirim
            barangnya <b>sesuai</b> dengan jumlah yang dipesan.
          </div>
        );
      default:
        return this.initMessageView(0);
    }
  }

  getReference(data) {
    if (data.inv_ref === null) {
      return "Belum Ada Kode Referensi";
    } else {
      return data.inv_ref;
    }
  }

  getDeliveryStatus(data) {
    if (!data.is_delivery) {
      return (
        <div className="print_status">
          <b className="normal_margin_right">{data.order_no}</b>{" "}
          <div className="tag_flashsale black_background default_text_normal_white">
            Bawa Pulang
          </div>
        </div>
      );
    } else {
      return (
        <div className="print_status">
          <b>{data.order_no}</b>
        </div>
      );
    }
  }

  initView(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div key={i} className="receipt_container">
          <div className="bold_text whole_padding print_header_container">
            SALES ORDER - {data[i].wholesaler_name}
          </div>
          <table className="table_print_info_container">
            <tbody>
              <tr>
                <td className="whole_padding default_text_normal_gray">
                  Nomor Penjualan
                </td>
                <td className="whole_padding default_text_normal_gray">:</td>
                <td className="whole_padding default_text_normal_gray_b">
                  {this.getDeliveryStatus(data[i])}
                </td>

                <td className="whole_padding default_text_normal_gray">
                  Kode Referensi
                </td>
                <td className="whole_padding default_text_normal_gray">:</td>
                <td className="whole_padding default_text_normal_gray_b bold_text">
                  {this.getReference(data[i])}
                </td>
              </tr>
              <tr>
                <td className="whole_padding default_text_normal_gray">
                  Nama Ritel
                </td>
                <td className="whole_padding default_text_normal_gray">:</td>
                <td className="whole_padding default_text_normal_gray_b bold_text">
                  {data[i].retail_name} ({data[i].retail_id})
                </td>

                <td className="whole_padding default_text_normal_gray">
                  Jenis Pembayaran
                </td>
                <td className="whole_padding default_text_normal_gray">:</td>
                <td className="whole_padding default_text_normal_gray_b bold_text">
                  {data[i].payment_desc}
                </td>
              </tr>
              <tr>
                <td className="whole_padding default_text_normal_gray">
                  Nomor Handphone
                </td>
                <td className="whole_padding default_text_normal_gray">:</td>
                <td className="whole_padding default_text_normal_gray_b bold_text">
                  {str_util.formatSeparatorHandphone(data[i].phone)}
                </td>

                <td className="whole_padding default_text_normal_gray">
                  Tanggal Pemesanan
                </td>
                <td className="whole_padding default_text_normal_gray">:</td>
                <td className="whole_padding default_text_normal_gray_b bold_text">
                  {data[i].order_date}
                </td>
              </tr>
              <tr>
                <td className="whole_padding default_text_normal_gray">
                  Alamat Tujuan
                </td>
                <td className="whole_padding default_text_normal_gray">:</td>
                <td
                  colSpan={4}
                  className="whole_padding default_text_normal_gray_b"
                >
                  {data[i].address}. Untuk melihat panduan lokasi silahkan{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={this.getMapsLink(data[i])}
                  >
                    klik disini
                  </a>
                  .
                </td>
              </tr>
              {this.getNoteView(data[i])}
            </tbody>
          </table>
          {this.initMessageView(data[i].flag_required)}
          <div className="normal_top_border vertical_padding_c side_margin default_text_normal_gray_b bold_text">
            Detail Pesanan
          </div>
          <table className="table_print_data_container">
            <thead>
              <tr>
                <th className="whole_padding default_text_normal_gray">
                  Nama Barang
                </th>
                <th className="whole_padding default_text_normal_gray special_th_data">
                  Satuan Besar
                </th>
                <th className="whole_padding default_text_normal_gray special_th_data">
                  Satuan Kecil
                </th>
                <th className="whole_padding default_text_normal_gray special_th_data">
                  Harga Satuan
                </th>
                <th className="whole_padding default_text_normal_gray">
                  Harga
                </th>
              </tr>
            </thead>
            <tbody>
              {this.initProductView(data[i].items)}
              {this.initSummaryView(data[i])}
            </tbody>
          </table>
        </div>
      );
    }
    return view;
  }

  // <div className='address_container'>
  //   <div className='normal_padding_bottom_b default_text_normal_gray_b bold_text'>Tujuan Pengiriman</div>
  //   <div className='default_text_normal_gray'>{data[i].address}. Untuk melihat panduan lokasi silahkan <a rel="noopener noreferrer" target='_blank' href={this.getMapsLink(data[i])}>klik disini</a>.</div>
  // </div>

  getNoteView(data) {
    if (data.note.length > 0) {
      return (
        <tr>
          <td className="whole_padding default_text_normal_gray">Note</td>
          <td className="whole_padding default_text_normal_gray">:</td>
          <td colSpan={4} className="whole_padding default_text_normal_gray_b">
            {data.note}.
          </td>
        </tr>
      );
    } else {
      return null;
    }
  }

  initSummaryView(data) {
    let view = [],
      i;
    view.push(
      <tr key="amount_total">
        <td
          colSpan="2"
          className="normal_top_border_b special_td_data whole_padding default_text_normal_gray"
        >
          Total Harga Produk :
        </td>
        <td
          colSpan="3"
          className="normal_top_border_b special_td_data whole_padding default_text_normal_gray"
        >
          {str_util.formatSeparator(data.amount)}
        </td>
      </tr>
    );
    view.push(
      <tr key="amount_delivery">
        <td
          colSpan="2"
          className="special_td_data whole_padding default_text_normal_gray"
        >
          Biaya Layanan Aplikasi :
        </td>
        <td
          colSpan="3"
          className="special_td_data whole_padding default_text_normal_gray"
        >
          {str_util.formatSeparator(data.delivery_price)}
        </td>
      </tr>
    );
    let temp_total = Number(data.amount) + Number(data.delivery_price);
    if (Number(data.vendor_admin) > 0) {
      temp_total += Number(data.vendor_admin);
      view.push(
        <tr key="amount_vendor_admin">
          <td
            colSpan="2"
            className="special_td_data whole_padding default_text_normal_gray"
          >
            Biaya Admin Pembayaran :
          </td>
          <td
            colSpan="3"
            className="special_td_data whole_padding default_text_normal_gray"
          >
            {str_util.formatSeparator(data.vendor_admin)}
          </td>
        </tr>
      );
    }
    if (Number(data.voucher_price) > 0) {
      temp_total -= Number(data.voucher_price);
      view.push(
        <tr key="amount_voucher">
          <td
            colSpan="2"
            className="special_td_data whole_padding default_text_normal_gray"
          >
            {data.voucher_title} :
          </td>
          <td
            colSpan="3"
            className="special_td_data whole_padding default_text_normal_gray"
          >
            -{str_util.formatSeparator(data.voucher_price)}
          </td>
        </tr>
      );
    }
    let temp_payments = [];
    for (i = 0; i < Object.keys(data.payments).length; i++) {
      if (data.payments[i].is_minus) {
        temp_total -= Number(data.payments[i].amount_sales);
        view.push(
          <tr key={"voucher-" + i}>
            <td
              colSpan="2"
              className="special_td_data whole_padding default_text_normal_gray"
            >
              {data.payments[i].description} :
            </td>
            <td
              colSpan="3"
              className="special_td_data whole_padding default_text_normal_gray"
            >
              -{str_util.formatSeparator(data.payments[i].amount_sales)}
            </td>
          </tr>
        );
      } else {
        temp_payments.push(data.payments[i]);
      }
    }
    view.push(
      <tr key="amount_pay" className="normal_border_bottom">
        <td
          colSpan="2"
          className="special_td_data whole_padding default_text_normal_gray bold_text"
        >
          Total Pembayaran :
        </td>
        <td
          colSpan="3"
          className="special_td_data whole_padding default_text_normal_gray_b bold_text"
        >
          {str_util.formatSeparator(temp_total.toString())}
        </td>
      </tr>
    );
    temp_total = 0;
    for (i = 0; i < Object.keys(temp_payments).length; i++) {
      temp_total += Number(temp_payments[i].amount_sales);
      view.push(
        <tr key={i}>
          <td
            colSpan="2"
            className="special_td_data whole_padding default_text_normal_gray"
          >
            {temp_payments[i].description} :
          </td>
          <td
            colSpan="3"
            className="special_td_data whole_padding default_text_normal_gray"
          >
            {str_util.formatSeparator(temp_payments[i].amount_sales)}
          </td>
        </tr>
      );
    }
    view.push(
      <tr key="amount_payments">
        <td
          colSpan="2"
          className="special_td_data whole_padding default_text_normal_gray bold_text"
        >
          Total :
        </td>
        <td
          colSpan="3"
          className="special_td_data whole_padding default_text_normal_gray_b bold_text"
        >
          {str_util.formatSeparator(temp_total + "")}
        </td>
      </tr>
    );
    for (i = 0; i < Object.keys(data.points).length; i++) {
      if (data.points[i].description !== "Point Mayora") {
        view.push(
          <tr key="additional_info">
            <td
              style={{ padding: "0 8px" }}
              colSpan="5"
              className="default_text_normal_gray"
              dangerouslySetInnerHTML={{
                __html: `<b>*${data.points[i].description}</b> (${
                  data.print_date
                }) = <b>${str_util.formatSeparator(
                  data.points[i].point + ""
                )}</b>`,
              }}
            ></td>
          </tr>
        );
      }
    }
    view.push(
      <tr key="additional_info">
        <td colSpan="5" className="whole_padding default_text_normal_gray">
          *Dokumen merupakan pencatatan pesanan, bukan dokumen faktur pesanan.
        </td>
      </tr>
    );
    return view;
  }

  getMapsLink(data) {
    return "https://maps.google.com/?q=" + data.latitude + "," + data.longitude;
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className="loading_container_b">
          <img className="big_image" src={gif_img} alt="" />
        </div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className="print_root">{this.initView(this.state.data)}</div>
        );
      } else {
        return (
          <div className="loading_container_b">
            <img className="big_image_b" src={img_error} alt="" />
            <div className="whole_padding_c default_text_big_gray">
              {this.state.error_message}
            </div>
          </div>
        );
      }
    }
  }
}
