import React, { Component } from 'react';

import LoadingDialog from './dialog/LoadingDialog';
import AdvSearchDialog from './dialog/AdvSearchDialog';
import TableSearch from './native/TableSearch';
import Pagination from './native/Pagination';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';
import img_expand_more from './resources/baseline_expand_more.png';
import img_setting from './resources/settings.png';

var request = require('../utilities/NetworkRequest');
var str_util = require('../utilities/StringUtil');
var view_util = require('../utilities/ViewUtil');
var cache_util = require('../utilities/CacheUtil');
var base64 = require('base-64');

export default class DeliveryReady extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_main_loading : false,
      is_loading : false,
      is_error : false,
      error_message : '',
      dialog_advance : {
        show : false,
        body : null
      },
      user_information : null,
      header_left : [],
      header_search : {},
      table_header : {},
      table_data : [],
      pagination : {},
      pagination_array : [{}]
    }
  }

  componentDidMount() {
    this.refreshData(false, false);
  }

  refreshData(table_only, is_filter) {
    this.setState({ is_main_loading : true }, () => {
      try {
        let decoded_input = this.state.user_information;
        if (decoded_input === null) {
          decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO).result.user_information;
          decoded_input.length_data = 50;
        }
        if (decoded_input.hasOwnProperty('last_time_stamp')) {
          delete decoded_input.last_time_stamp;
        }
        if (!is_filter) {
          if (decoded_input.hasOwnProperty('filter')) {
            delete decoded_input.filter;
          }
        }
        request.onRequestData(request.OPTION_API_MAIN, '/show/delivery_ready', decoded_input, res_data => {
          if (res_data.code === 1) {
            let temp_pagination_array = [];
            temp_pagination_array.push(res_data.result.res_data.pagination);
            if (table_only) {
              this.setState({
                is_main_loading : false,
                is_error : false,
                table_data : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            } else {
              this.setState({
                is_main_loading : false,
                is_error : false,
                dialog_advance : {
                  show : false,
                  body : res_data.result.filterV2
                },
                user_information : decoded_input,
                header_left : res_data.result.header.left,
                header_search : res_data.result.header.search,
                table_header : res_data.result.res_data.header,
                table_data : res_data.result.res_data.arrays,
                pagination : res_data.result.res_data.pagination,
                pagination_array : temp_pagination_array
              });
            }
          } else {
            this.setState({
              is_main_loading : false,
              is_error : true,
              error_message : res_data.message
            });
          }
        });
      } catch (e) {
        this.setState({
          is_main_loading : false,
          is_error : true,
          error_message : e.message
        });
      }
    });
  }

  initSearchHeader() {
    return (
      <div className='search_root_container'>
        <TableSearch header={this.state.header_search} table={this.state.table_data} onTableUpdate={(data_table) => this.setState({ table_data : data_table })} />
        <div onClick={() => this.showDialogAdvance('open', this.state.dialog_advance.body)} className='container_image_b'>
          <img className='mini_image_b' src={img_setting} alt=''/>
        </div>
      </div>
    );
  }

  showDialogAdvance(action, body) {
    let temp = this.state.dialog_advance;
    temp.show = !temp.show;
    switch (action) {
      case 'next' :
        let temp_info = this.state.user_information;
        temp_info.filter = JSON.stringify(body);
        this.setState({
          user_information : temp_info,
          dialog_advance : temp
        }, () => { this.refreshData(true, true); });
        break;
      default :
        this.setState({ dialog_advance : temp });
        break;
    }
  }

  onLeftHeaderActionClicked(position) {
    let temp_header_left = this.state.header_left;
    switch (temp_header_left[position].key) {
      case 'refresh' :
        this.refreshData(true, false);
        break;
      case 'print' :
        let temp_table_data = this.state.table_data, set_order_id = '';
        for (let i = 0; i < Object.keys(temp_table_data).length; i++) {
          if (temp_table_data[i].is_selected) {
            set_order_id += temp_table_data[i].order_no + '#';
          }
        }
        if (set_order_id.length > 0) {
          let print_params = {
            user_information : this.state.user_information,
            set_id : set_order_id.substr(0,set_order_id.length-1),
            view_id : 1
          }
          window.open(request.IP_PATH + '/print/' + base64.encode(JSON.stringify(print_params)), '_blank');
        }
        break;
      default :
        break;
    }
  }

  initLeftHeader(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div onClick={this.onLeftHeaderActionClicked.bind(this, i)} key={data[i].key} className='container_image'>
          <img className='mini_image_b' src={require(data[i].image + '')} alt=''/>
        </div>
      );
    }
    return view;
  }

  initFilterHeader(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      switch (data[i].type) {
        case 'dropdown':
          view.push(
            <div onClick={this.onFilterDropdownClicked.bind(this,i)} key={data[i].key} className='dropdown_display dropdown_container'>
              <div className='side_padding dropdown_chooser'>
                <div className='full_flex default_text_normal_gray'>{data[i].selected.label}</div>
                <div className='center_content circle_background_b'>
                  <img className='mini_image_b' src={img_expand_more} alt=''/>
                </div>
              </div>
              {this.showDropdown(data[i])}
            </div>
          );
          break;
        default :
          // DO NOTHING
          break;
      }
    }
    return view;
  }

  onCheckboxClicked(is_header, position) {
    // console.log(this.state.table_header);
    
    let temp_table_header = this.state.table_header;
    let temp_table_data = this.state.table_data;
    if (is_header) { // kalo selected ALL
      temp_table_header.is_selected = !temp_table_header.is_selected;
      for (let i = 0; i < Object.keys(temp_table_data).length; i++) {
        temp_table_data[i].is_selected = temp_table_header.is_selected;
      }
      this.setState({
        table_header : temp_table_header,
        table_data : temp_table_data
      });
    } else { // kalo selected satu2
      temp_table_data[position].is_selected = !temp_table_data[position].is_selected;
      if (!temp_table_data[position].is_selected && temp_table_header.is_selected) {
        temp_table_header.is_selected = !temp_table_header.is_selected;
        this.setState({
          table_header : temp_table_header,
          table_data : temp_table_data
        });
      } else {
        this.setState({
          table_data : temp_table_data
        });
      }
    }
  }

  initTableView(data) {
    return (
      <table className='table_ready_delivery'>
        <thead>
          <tr>
            <th className='whole_padding'><input type="checkbox" checked={data.is_selected} onChange={this.onCheckboxClicked.bind(this,true,0)} name="select_all" value="all"/></th>
            <th className='whole_padding default_text_normal_gray'>{data.order_no}</th>
            <th className='whole_padding default_text_normal_gray'>{data.retail_id}</th>
            <th className='whole_padding default_text_normal_gray'>{data.retail_name}</th>
            <th className='whole_padding default_text_normal_gray'>{data.inv_ref}</th>
            <th className='whole_padding default_text_normal_gray'>{data.total_items}</th>
            <th className='whole_padding default_text_normal_gray'>{data.amount}</th>
            <th className='whole_padding default_text_normal_gray'>{data.payment_method}</th>
            <th className='main_column whole_padding default_text_normal_gray'>{data.delivery_notes}</th>
          </tr>
        </thead>
        <tbody>
          {this.initDataTable(this.state.table_data)}
        </tbody>
      </table>
    );
  }

  initDataTable(data) {
    let view = [], i, i_found = 0;
    for (i = 0; i < Object.keys(data).length; i++) {
      if (data[i].show_data) {
        i_found++;
        view.push(
          <tr key={i}>
            <td className='whole_padding'><input type="checkbox" checked={data[i].is_selected} onChange={this.onCheckboxClicked.bind(this,false,i)} name={i} value={data[i].order_no}/></td>
            <td className='whole_padding default_text_normal_gray'>{data[i].order_no}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].retail_id}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].retail_name}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].inv_ref}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].total_items}</td>
            <td className='whole_padding default_text_normal_gray'>{str_util.formatSeparator(data[i].amount)}</td>
            <td className='side_padding'>{view_util.getPaymentType(data[i].payment_method)}</td>
            <td className='whole_padding default_text_normal_gray'>{data[i].delivery_notes}</td>
          </tr>
        );
      }
    }
    if (i_found < 1) {
      view.push(view_util.getZeroRow(10));
    }
    return view;
  }

  onPaginationClicked(index, data) {
    let temp_body = this.state.user_information;
    if (index === 9999 && data === null) {
      temp_body.last_time_stamp = this.state.pagination.time_stamp;
    } else {
      temp_body.last_time_stamp = this.state.pagination_array[index].time_stamp_before;
    }
    this.requestOrder(temp_body, data);
  }

  requestOrder(body, temp_pagination_array) {
    this.setState({
      is_loading : true
    }, () => {
      request.onRequestData(request.OPTION_API_MAIN, '/show/delivery_ready', body, res_data => {
        if (res_data.code === 1) {
          if (temp_pagination_array === null) {
            temp_pagination_array = this.state.pagination_array;
            temp_pagination_array.push(res_data.result.res_data.pagination);
          }
          this.setState({
            is_loading : false,
            is_error : false,
            table_data : res_data.result.res_data.arrays,
            pagination : res_data.result.res_data.pagination,
            pagination_array : temp_pagination_array
          });
        } else {
          this.setState({
            is_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    });
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className='root_delivery_monitoring'>
            <LoadingDialog showDialog={this.state.is_loading}/>
            {this.state.dialog_advance.show ? <AdvSearchDialog body={this.state.dialog_advance.body} onCloseListener={(action, body) => this.showDialogAdvance(action, body)}/> : null}
            <div className='header_container'>
              <div className='flex_left'>
                {this.initLeftHeader(this.state.header_left)}
              </div>
              <div className='flex_right'>
                {this.initSearchHeader()}
              </div>
            </div>
            {this.initTableView(this.state.table_header)}
            <Pagination pagination={this.state.pagination} pagination_array={this.state.pagination_array} onChangePage={(index, page_array) => this.onPaginationClicked(index, page_array)}/>
          </div>
        );
      } else {
        return (
          <div className='loading_container'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
