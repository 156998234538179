import React, { Component } from 'react';

// import Dropdown from './native/Dropdown';
import DropdownSearch from './native/DropdownSearch';

import gif_img from './resources/loading.gif';
import img_error from './resources/error.png';

var base64 = require('base-64');
var request = require('../utilities/NetworkRequest');
var str_util = require('../utilities/StringUtil');
var cache_util = require('../utilities/CacheUtil');

function initPrintMargin() {
  let data = [];
  for (let i = 0; i <= 20; i+= 0.5) {
    data.push({
      key : `margin_${i}mm`,
      value : {margin: `0mm ${i}mm 0mm ${i}mm`},
      label : `Margin ${i} MM`
    });
  }
  return data;
}

function initFontSize() {
  let data = [];
  for (let i = 5; i <= 20; i++) {
    data.push({
      key : `${i}pt`,
      value : {fontSize:`${i}pt`},
      label : `Ukuran Text ${i} Pt`
    });
  }
  return data;
}

function initPrintPaper() {
  let data = [{
    key : 'auto',
    value : {width:'auto'},
    label : 'Lebar Auto'
  }];
  for (let i = 20; i <= 200; i++) {
    data.push({
      key : `${i}mm`,
      value : {width:`${i}mm`},
      label : `Lebar Kertas ${i} MM`
    });
  }
  return data;
}

function initPrintWord() {
  let data = [];
  for (let i = 10; i <= 200; i++) {
    data.push({
      key : i,
      label : `Panjang ${i} Kata`
    });
  }
  return data;
}

export default class PrintMiniOrder extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data : [],
      stt_str_obj : [],
      selected_print_margin : {
        key : 'margin_5mm',
        value : {margin: '0mm 5mm 0mm 5mm'},
        label : 'Margin 5 MM'
      },
      stt_print_margin : {
        selected : {
          key : 'margin_5mm',
          value : {margin: '0mm 5mm 0mm 5mm'},
          label : 'Margin 5 MM'
        },
        text : 'category_margin',
        is_expand : false,
        data : initPrintMargin()
      },
      selected_print_fsize : {
        key : '6pt',
        value : {fontSize: '6.1pt'},
        label : 'Ukuran Text 6 Pt'
      },
      stt_print_fsize : {
        selected : {
          key : '6pt',
          value : {fontSize: '6.1pt'},
          label : 'Ukuran Text 6 Pt'
        },
        text : 'category_fsize',
        is_expand : false,
        data : initFontSize()
      },
      selected_print_paper : {
        key : 'auto',
        value : {width:'auto'},
        label : 'Lebar Auto'
      },
      stt_print_paper : {
        selected : {
          key : 'auto',
          value : {width:'auto'},
          label : 'Lebar Auto'
        },
        text : 'category_paper',
        is_expand : false,
        data : initPrintPaper()
      },
      stt_print_word : {
        selected : {
          key : 37,
          label : 'Panjang 37 Kata'
        },
        text : 'category_word',
        is_expand : false,
        data : initPrintWord()
      },
      stt_print_percentage : {
        selected : {
          key : false,
          label : 'Non-Aktifkan Indentasi'
        },
        text : 'category_word',
        is_expand : false,
        data : [
          {
            key : true,
            label : 'Aktifkan Indentasi'
          },
          {
            key : false,
            label : 'Non-Aktifkan Indentasi'
          }
        ]
      },
      is_main_loading : true,
      is_error : false,
      error_message : ''
    }
  }

  componentDidMount() {
    try {
      // GET PREVIOUS SETTING
      let prev_setting = cache_util.onGetCacheData(cache_util.CACHE_PRINT_SETTING);
      let _selected_print_margin = this.state.selected_print_margin;
      let _stt_print_margin = this.state.stt_print_margin;
      let _selected_print_fsize = this.state.selected_print_fsize;
      let _stt_print_fsize = this.state.stt_print_fsize;
      let _selected_print_paper = this.state.selected_print_paper;
      let _stt_print_paper = this.state.stt_print_paper;
      let _stt_print_word = this.state.stt_print_word;
      // let _stt_print_percentage = this.state.stt_print_percentage;
      if (prev_setting !== null) {
        _selected_print_margin = prev_setting.selected_print_margin;
        _stt_print_margin.selected = prev_setting.selected_print_margin;
        _selected_print_fsize = prev_setting.selected_print_fsize;
        _stt_print_fsize.selected = prev_setting.selected_print_fsize;
        _selected_print_paper = prev_setting.selected_print_paper;
        _stt_print_paper.selected = prev_setting.selected_print_paper;
        _stt_print_word.selected = prev_setting.selected_print_word;
        // if (prev_setting.selected_print_percentage !== undefined) {
        //   _stt_print_percentage.selected = prev_setting.selected_print_percentage;
        // }
      }
      // GET ORDER
      let decoded_input = JSON.parse(base64.decode(this.props.match.params.data));
      let body = {
        wholesaler_id : decoded_input.user_information.wholesaler_id,
        order_number : JSON.stringify(decoded_input.set_id.split('#')),
        page_id : decoded_input.view_id
      }
      request.onRequestData(request.OPTION_API_MAIN, '/print/order', body, res_data => {
        if (res_data.code === 1) {
          let temp_stt = this.initReceiptOrder(res_data.result);
          this.setState({
            is_main_loading : false,
            is_error : false,
            data : res_data.result,
            stt_str_obj : temp_stt,
            selected_print_margin : _selected_print_margin,
            stt_print_margin : _stt_print_margin,
            selected_print_fsize : _selected_print_fsize,
            stt_print_fsize : _stt_print_fsize,
            selected_print_paper : _selected_print_paper,
            stt_print_paper : _stt_print_paper,
            stt_print_word : _stt_print_word
          });
        } else {
          this.setState({
            is_main_loading : false,
            is_error : true,
            error_message : res_data.message
          });
        }
      });
    } catch (e) {
      this.setState({
        is_main_loading : false,
        is_error : true,
        error_message : e.message
      });
    }
  }

  getUnitValue(data) {
    if (Number(data.qty_real_order) > 0) {
      return Math.trunc(Number(data.amount_sales_order)/Number(data.qty_real_order));
    } else {
      return 0;
    }
  }

  getUnitMeasurement(data, j) {
    if (data.items[j].uom_a === data.items[j].uom_b) {
      return data.items[j].qty_real_a_processed + ' ' + data.items[j].uom_a;
    } else {
      return data.items[j].qty_real_a_processed + ' ' + data.items[j].uom_a + ' ' + data.items[j].qty_real_b_processed + ' ' + data.items[j].uom_b;
    }
  }

  pushTextReceipt(str_obj) {
    let view_text = [];
    let text = '';

    let i = 0, step, n_obj;

    for (let j = 0; j < Object.keys(str_obj).length; j++) {
      if (str_obj[j].math === 'ceil') {
        n_obj = Math.ceil(str_obj[j].percentage * this.state.stt_print_word.selected.key /100);
      } else {
        n_obj = Math.floor(str_obj[j].percentage * this.state.stt_print_word.selected.key /100);
      }
      step = 0;
      if (i >= this.state.stt_print_word.selected.key) {
        i = 0;
        text = '';
      }
      while (true) {
        if (str_obj[j].text.length === 0) {
          text += str_obj[j].divider;
        } else {
          text += str_obj[j].text[0];
        }
        str_obj[j].text.splice(0,1);
        if (str_obj[j].text.length === 0) { str_obj[j].is_continue = false; }
        step++;
        i++;
        if (step === n_obj) {
          if (str_obj[j].is_continue) {
            view_text.push( <pre style={this.state.selected_print_fsize.value} key={str_obj[j].key + '-' + i} className='default_text_normal_black'>{text}</pre> );
            text = '';
            step = 0;
          } else {
            if (i >= this.state.stt_print_word.selected.key-1) {
              view_text.push( <pre style={this.state.selected_print_fsize.value} key={str_obj[j].key + '-' + i} className='default_text_normal_black'>{text}</pre> );
            }
            break;
          }
        }
      }
    }


    return view_text;
  }

  initMiniReceipt(idx, data) {
    let str_obj = [{
        text : [],
        key : idx + 'divider_a',
        math : 'ceil',
        percentage : 100,
        divider : ' ',
        is_continue : true
      },
      {
        text : ('SALES ORDER - ' + data.wholesaler_name).split(''),
        key : idx + 'wholesaler',
        math : 'ceil',
        percentage : 100,
        divider : ' ',
        is_continue : true
      },
      {
        text : (data.order_no + ' - ' + data.retail_name).split(''),
        key : idx + 'retail',
        math : 'ceil',
        percentage : 100,
        divider : ' ',
        is_continue : true
      },
      {
        text : [],
        key : idx + 'divider_b',
        math : 'ceil',
        percentage : 100,
        divider : ' ',
        is_continue : true
      }
    ]
    let product_price = 0, total_payments = 0, j;
    for (j = 0; j < Object.keys(data.items).length; j++) {
      product_price += Number(data.items[j].amount_sales_order);
      str_obj.push({
        text : (data.items[j].pcode_name + ' (' + this.getUnitMeasurement(data, j) + ')').split(''),
        key : idx + 'pcode-' + j,
        math : 'ceil',
        percentage : 100,
        divider : ' ',
        is_continue : true
      });
      if (this.state.stt_print_percentage.selected.key) {
        str_obj.push({
          text : (data.items[j].qty_real_order.toString()).split(''),
          key : idx + 'a_price-' + j,
          math : 'floor',
          percentage : 14,
          divider : ' ',
          is_continue : false
        },{
          text : ['X'],
          key : idx + 'b_price-' + j,
          math : 'ceil',
          percentage : 7,
          divider : ' ',
          is_continue : false
        },{
          text : str_util.formatSeparator( this.getUnitValue(data.items[j]).toString() ).split(''),
          key : idx + 'c_price-' + j,
          math : 'floor',
          percentage : 28,
          divider : ' ',
          is_continue : false
        },{
          text : ['='],
          key : idx + 'd_price-' + j,
          math : 'ceil',
          percentage : 3,
          divider : ' ',
          is_continue : false
        },{
          text : (' Rp ' + str_util.formatSeparator(data.items[j].amount_sales_order)).split(''),
          key : idx + 'e_price-' + j,
          math : 'floor',
          percentage : 48,
          divider : ' ',
          is_continue : false
        });
      } else {
        str_obj.push({
          text : (`${data.items[j].qty_real_order.toString()} X ${str_util.formatSeparator( this.getUnitValue(data.items[j]).toString() )} = Rp ${str_util.formatSeparator(data.items[j].amount_sales_order)}`).split(''),
          key : `${idx}batch_pcode-${j}`,
          math : 'ceil',
          percentage : 100,
          divider : ' ',
          is_continue : true
        });
      }
    }
    str_obj.push({
      text : [],
      key : idx + 'divider_c',
      math : 'ceil',
      percentage : 100,
      divider : ' ',
      is_continue : true
    });
    if (this.state.stt_print_percentage.selected.key) {
      str_obj.push({
        text : 'Harga Produk'.split(''),
        key : idx + 't_price_a',
        math : 'floor',
        percentage : 49,
        divider : ' ',
        is_continue : false
      },{
        text : ['='],
        key : idx + 't_price_b',
        math : 'ceil',
        percentage : 3,
        divider : ' ',
        is_continue : false
      },{
        text : (' Rp ' + str_util.formatSeparator(product_price.toString())).split(''),
        key : idx + 't_price_c',
        math : 'floor',
        percentage : 48,
        divider : ' ',
        is_continue : false
      },
      {
        text : 'Biaya Layanan Aplikasi'.split(''),
        key : idx + 'd_price_a',
        math : 'floor',
        percentage : 49,
        divider : ' ',
        is_continue : false
      },{
        text : ['='],
        key : idx + 'd_price_b',
        math : 'ceil',
        percentage : 3,
        divider : ' ',
        is_continue : false
      },{
        text : (' Rp ' + str_util.formatSeparator(data.delivery_price.toString())).split(''),
        key : idx + 'd_price_c',
        math : 'floor',
        percentage : 48,
        divider : ' ',
        is_continue : false
      });
    } else {
      str_obj.push({
        text : (`Harga Produk = Rp ${str_util.formatSeparator(product_price.toString())}`).split(''),
        key : idx + 'batch_info_price',
        math : 'ceil',
        percentage : 100,
        divider : ' ',
        is_continue : true
      },{
        text : (`Biaya Layanan Aplikasi = Rp ${str_util.formatSeparator(data.delivery_price.toString())}`).split(''),
        key : idx + 'batch_info_delivery',
        math : 'ceil',
        percentage : 100,
        divider : ' ',
        is_continue : true
      });
    }
    total_payments += product_price + Number(data.delivery_price);

    if (Number(data.vendor_admin) > 0) {
      if (this.state.stt_print_percentage.selected.key) {
        str_obj.push({
          text : 'Biaya Admin'.split(''),
          key : idx + 'd_admin_a',
          math : 'floor',
          percentage : 49,
          divider : ' ',
          is_continue : false
        },{
          text : ['='],
          key : idx + 'd_admin_b',
          math : 'ceil',
          percentage : 3,
          divider : ' ',
          is_continue : false
        },{
          text : (' Rp ' + str_util.formatSeparator(data.vendor_admin.toString())).split(''),
          key : idx + 'd_admin_c',
          math : 'floor',
          percentage : 48,
          divider : ' ',
          is_continue : false
        });
      } else {
        str_obj.push({
          text : (`Biaya Admin = Rp ${str_util.formatSeparator(data.vendor_admin.toString())}`).split(''),
          key : idx + 'd_admin',
          math : 'ceil',
          percentage : 100,
          divider : ' ',
          is_continue : true
        });
      }
      total_payments += Number(data.vendor_admin);
    }

    if (Number(data.voucher_price) > 0) {
      if (this.state.stt_print_percentage.selected.key) {
        str_obj.push({
          text : data.voucher_title.split(''),
          key : idx + 'd_voucher_a',
          math : 'floor',
          percentage : 49,
          divider : ' ',
          is_continue : false
        },{
          text : ['='],
          key : idx + 'd_voucher_b',
          math : 'ceil',
          percentage : 3,
          divider : ' ',
          is_continue : false
        },{
          text : ('-Rp ' + str_util.formatSeparator(data.voucher_price.toString())).split(''),
          key : idx + 'd_voucher_c',
          math : 'floor',
          percentage : 48,
          divider : ' ',
          is_continue : false
        });
      } else {
        str_obj.push({
          text : (`${data.voucher_title} = -Rp ${str_util.formatSeparator(data.voucher_price.toString())}`).split(''),
          key : idx + 'batch_voucher',
          math : 'ceil',
          percentage : 100,
          divider : ' ',
          is_continue : true
        });
      }
      total_payments -= Number(data.voucher_price);
    }
    let temp_obj = [];
    for (j = 0; j < Object.keys(data.payments).length; j++) {
      if (data.payments[j].is_minus) {
        total_payments -= Number(data.payments[j].amount_sales);
        if (this.state.stt_print_percentage.selected.key) {
          str_obj.push({
            text : data.payments[j].description.split(''),
            key : idx + 'pay_price_a' + j,
            math : 'floor',
            percentage : 49,
            divider : ' ',
            is_continue : false
          },{
            text : ['='],
            key : idx + 'pay_price_b' + j,
            math : 'ceil',
            percentage : 3,
            divider : ' ',
            is_continue : false
          },{
            text : ('-Rp ' + str_util.formatSeparator(data.payments[j].amount_sales.toString())).split(''),
            key : idx + 'pay_price_c' + j,
            math : 'floor',
            percentage : 48,
            divider : ' ',
            is_continue : false
          });
        } else {
          str_obj.push({
            text : (`${data.payments[j].description} = -Rp ${str_util.formatSeparator(data.payments[j].amount_sales.toString())}`).split(''),
            key : `${idx}batch_pay_price${j}`,
            math : 'ceil',
            percentage : 100,
            divider : ' ',
            is_continue : true
          });
        }
      } else {
        if (this.state.stt_print_percentage.selected.key) {
          temp_obj.push({
            text : data.payments[j].description.split(''),
            key : idx + 'pay_price_a' + j,
            math : 'floor',
            percentage : 49,
            divider : ' ',
            is_continue : false
          },{
            text : ['='],
            key : idx + 'pay_price_b' + j,
            math : 'ceil',
            percentage : 3,
            divider : ' ',
            is_continue : false
          },{
            text : (' Rp ' + str_util.formatSeparator(data.payments[j].amount_sales.toString())).split(''),
            key : idx + 'pay_price_c' + j,
            math : 'floor',
            percentage : 48,
            divider : ' ',
            is_continue : false
          });
        } else {
          str_obj.push({
            text : (`${data.payments[j].description} = Rp ${str_util.formatSeparator(data.payments[j].amount_sales.toString())}`).split(''),
            key : `${idx}batch_pay_price${j}`,
            math : 'ceil',
            percentage : 100,
            divider : ' ',
            is_continue : true
          });
        }
      }
    }
    if (this.state.stt_print_percentage.selected.key) {
      str_obj.push({
        text : 'Total Bayar'.split(''),
        key : idx + 't_payments_a',
        math : 'floor',
        percentage : 49,
        divider : ' ',
        is_continue : false
      },{
        text : ['='],
        key : idx + 't_payments_b',
        math : 'ceil',
        percentage : 3,
        divider : ' ',
        is_continue : false
      },{
        text : (' Rp ' + str_util.formatSeparator(total_payments.toString())).split(''),
        key : idx + 't_payments_c',
        math : 'floor',
        percentage : 48,
        divider : ' ',
        is_continue : false
      });
    } else {
      str_obj.push({
        text : (`Total Bayar = Rp ${str_util.formatSeparator(total_payments.toString())}`).split(''),
        key : `${idx}batch_t_payments`,
        math : 'ceil',
        percentage : 100,
        divider : ' ',
        is_continue : true
      });
    }
    str_obj.push({
      text : [],
      key : idx + 'divider_d',
      math : 'ceil',
      percentage : 100,
      divider : ' ',
      is_continue : true
    });
    str_obj = str_obj.concat(temp_obj);

    if (Object.keys(data.points).length > 0) {
      str_obj.push({
        text : [],
        key : idx + 'divider_g',
        math : 'ceil',
        percentage : 100,
        divider : ' ',
        is_continue : true
      });
      for (j = 0; j < Object.keys(data.points).length; j++) {
        if (data.points[j].description !== "Point Mayora") {
          str_obj.push({
            text : `*${data.points[j].description} (${data.print_date}) = ${str_util.formatSeparator(data.points[j].point + '')}`.split(''),
            key : idx + '_points_' + j,
            math : 'ceil',
            percentage : 100,
            divider : ' ',
            is_continue : true
          });
        }
      }
    }

    str_obj.push({
        text : [],
        key : idx + 'divider_e',
        math : 'ceil',
        percentage : 100,
        divider : ' ',
        is_continue : true
      },{
      text : '*Dokumen ini merupakan pencatatan pesanan, bukan dokumen faktur pesanan.'.split(''),
      key : idx + 'important',
      math : 'ceil',
      percentage : 100,
      divider : ' ',
      is_continue : true
    },{
    text : [''],
    key : idx + 'divider_f',
    math : 'ceil',
    percentage : 100,
    divider : '*',
    is_continue : true
  });
    return str_obj;
  }

  initReceiptOrder(data) {
    let temp_stt_str_obj = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      temp_stt_str_obj = temp_stt_str_obj.concat(this.initMiniReceipt(i, data[i]));
    }
    return temp_stt_str_obj;
  }

  onRefreshPage() {
    let cache_obj = {
      selected_print_margin : this.state.stt_print_margin.selected,
      selected_print_fsize : this.state.stt_print_fsize.selected,
      selected_print_paper : this.state.stt_print_paper.selected,
      selected_print_word : this.state.stt_print_word.selected,
      selected_print_percentage : this.state.stt_print_percentage.selected
    }
    cache_util.onSaveCache(cache_util.CACHE_PRINT_SETTING, JSON.stringify(cache_obj));
    let temp = this.initReceiptOrder(this.state.data);
    this.setState({
      stt_str_obj : temp,
      selected_print_fsize : this.state.stt_print_fsize.selected,
      selected_print_paper : this.state.stt_print_paper.selected,
      selected_print_margin : this.state.stt_print_margin.selected
    });
  }

  // <div className='wrapper_product_action normal_margin_top_e'>
  //   <Dropdown data={this.state.stt_print_percentage}/>
  // </div>

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className='loading_container_b'><img className='big_image' src={gif_img} alt='' /></div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div style={this.state.selected_print_paper.value} className='print_mini_container' >
            <div className='print_action hide'>
              <div className='wrapper_product_action'>
                <DropdownSearch data={this.state.stt_print_word}/>
                <div className='normal_margin_left'>
                  <DropdownSearch className='normal_margin_left' data={this.state.stt_print_paper}/>
                </div>
              </div>
              <div className='wrapper_product_action normal_margin_top_e'>
                <DropdownSearch data={this.state.stt_print_fsize}/>
                <div className='normal_margin_left'>
                  <DropdownSearch data={this.state.stt_print_margin}/>
                </div>
              </div>
              <div className='normal_margin_top_h wrapper_product_action full_flex'>
                <div className='process_button_f'/>
                <div onClick={this.onRefreshPage.bind(this)} className='action_button process_button_b normal_margin_left orange_background default_text_normal_white'>Perbaharui Halaman</div>
              </div>
            </div>
            <div style={this.state.selected_print_margin.value} className='print_mini_root'> {this.pushTextReceipt(this.state.stt_str_obj)} </div>
          </div>
        );
      } else {
        return (
          <div className='loading_container_b'>
            <img className='big_image_b' src={img_error} alt='' />
            <div className='whole_padding_c default_text_big_gray'>{this.state.error_message}</div>
          </div>
        );
      }
    }
  }

}
